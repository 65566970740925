













































































































































































































import { defineComponent } from "@vue/composition-api";
import { differenceInHours, isToday } from "date-fns";
import { mapActions, mapState } from "pinia";
import type { LesUitgebreid } from "@/lib/backend/coach.api";
import { useAppStore } from "@/pinia/app";
import { useGroepslesStore } from "@/pinia/groepsles";
import { useLocationStore } from "@/pinia/location";
import { getDateFromDatum, getDateFromDatumAndTijd, getDayMonth, getWeekDay } from "@/utils/date";
import { getCoachImage } from "@/utils/images";

type Data = {
	loading: boolean;
	lessen: LesUitgebreid[];
	limits: number[];
	limit: number;
	page: number;
	filters: Record<string, string>;
};

export default defineComponent({
	name: "PfgGroepslessen",
	data(): Data {
		return {
			loading: false,
			limits: [25, 50, 100],
			limit: 25,
			page: 1,
			lessen: [],
			filters: {
				tijd: "vandaag",
				trainer: "all",
				zaal: "all",
				locatie: "all",
				groepsles: "all",
			},
		};
	},
	computed: {
		...mapState(useLocationStore, {
			location: "location",
			locations: "locationsSortedByName",
		}),
		...mapState(useAppStore, ["occupancyFormat"]),
		groepslessen(): string[] {
			return (this.lessen as LesUitgebreid[])
				.reduce<string[]>((acc: string[], les: LesUitgebreid): string[] => {
					if (acc.indexOf(les.groepsles_naam) === -1) acc.push(les.groepsles_naam);

					return acc;
				}, [])
				.sort((a: string, b: string): 1 | 0 | -1 => {
					if (a > b) return 1;
					else if (a < b) return -1;
					else return 0;
				});
		},
		zalen(): string[] {
			return (this.lessen as LesUitgebreid[]).reduce<string[]>(
				(acc: string[], les: LesUitgebreid): string[] => {
					if (
						acc.indexOf(`${les.zaal_naam} - ${les.vestiging_naam}`) === -1 &&
						this.filters.locatie === les.vestiging_naam
					)
						acc.push(`${les.zaal_naam} - ${les.vestiging_naam}`);

					return acc;
				},
				[],
			);
		},
		trainers(): string[] {
			return (this.lessen as LesUitgebreid[])
				.reduce<string[]>((acc: string[], les: LesUitgebreid): string[] => {
					if (acc.indexOf(les.trainer_naam) === -1) acc.push(les.trainer_naam);

					return acc;
				}, [])
				.sort((a: string, b: string): 1 | 0 | -1 => {
					if (a < b) return -1;
					else if (a > b) return 1;
					else return 0;
				});
		},
		filteredLessen(): LesUitgebreid[] {
			if (!this.lessen.length) return [];

			const NOW = new Date();

			return this.lessen
				.filter(
					(les: LesUitgebreid): boolean =>
						(les.vestiging_naam === this.filters.locatie || this.filters.locatie === "all") &&
						(les.trainer_naam === this.filters.trainer || this.filters.trainer === "all") &&
						((this.filters.zaal.split(" - ")[0] === les.zaal_naam &&
							this.filters.zaal.split(" - ")[1] === les.vestiging_naam) ||
							this.filters.zaal === "all") &&
						(this.filters.groepsles === les.groepsles_naam || this.filters.groepsles === "all"),
				)
				.filter((les: LesUitgebreid): boolean => {
					if (this.filters.tijd === "aankomend")
						return differenceInHours(NOW, getDateFromDatumAndTijd(les.datum, les.tijd)) < 0;
					else if (this.filters.tijd === "afgelopen")
						return differenceInHours(NOW, getDateFromDatumAndTijd(les.datum, les.tijd)) > 0;
					else if (this.filters.tijd === "vandaag")
						return isToday(getDateFromDatumAndTijd(les.datum, les.tijd));
					else return true;
				});
		},
		limitedData(): LesUitgebreid[] {
			if (!this.filteredLessen) return [];

			if (!this.limit) return this.filteredLessen;

			return (this.filteredLessen as LesUitgebreid[]).filter(
				(_: LesUitgebreid, i: number): boolean => {
					if (i >= (this.page - 1) * this.limit && i < this.page * this.limit) return true;

					return false;
				},
			);
		},
		pages(): number {
			if (!this.filteredLessen || !this.filteredLessen.length || !this.limit) return 0;
			const pages = Math.ceil(this.filteredLessen.length / this.limit);
			return pages;
		},
	},
	watch: {
		filters: {
			handler() {
				this.page = 1;
			},
			deep: true,
		},
		// reset zaal to all on location change
		"filters.locatie": {
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					this.filters.zaal = "all";
				}
			},
		},
	},
	async created() {
		await this.check();
	},
	methods: {
		...mapActions(useLocationStore, {
			initLocation: "init",
		}),
		...mapActions(useGroepslesStore, ["getActueleGroepslessen"]),
		async check() {
			this.loading = true;

			await this.initLocation();
			this.filters.locatie = this.location?.naam ?? "all";

			await this.getRequiredInfo();

			this.loading = false;
		},
		async getRequiredInfo() {
			this.lessen = await this.getActueleGroepslessen();
		},
		resetFilters() {
			Object.keys(this.filters).forEach((key: string) => {
				this.filters[key] = "all";
			});

			this.filters.tijd = "vandaag";
		},
		paginate(page: number) {
			this.page = page;
		},
		getCoachImage,
		getWeekDay,
		getDayMonth,
		getDateFromDatum,
	},
});
